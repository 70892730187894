// filter logic
$.fn.filterSidebar = function (txt) {
	if (txt.length === 0) {
		$('.api-list li').each(function () {
			$(this).show();
			if (typeof $(this).attr('aria-expanded') !== 'undefined' && $(this).attr('aria-expanded') === 'true') {
				if (!$(this).next()
					.children()
					.hasClass('active')) {
					$(this).click();
				}
			}
		});
		$('.api-list #selectedAPI').parents("ul:not(.api-list)").prev('li[aria-expanded="false"]').click();
	} else {
		txt = txt.split(' ');
		$(".api-list li[aria-expanded='false']").click();
		txt.forEach((item) => {
			$('.api-list li').each(function (idx, li) {
				if ($(this).is(':visible')) {
					if (typeof $(this).attr('data-target') === 'undefined') {
						const regex = new RegExp(item, 'g');
						if (typeof $(this).children('a')
							.attr('href') !== 'undefined' && $(this).children('a')
							.attr('href')
							.toUpperCase()
							.search(regex) > 0) {
							$(this).show();
						} else if ($(this).children('a')
							.text()
							.toUpperCase()
							.search(regex) > 0 || $(this).children('a')
							.text()
							.toUpperCase()
							.includes(item)) {
							$(this).show();
						} else {
							$(this).hide();
						}
					} else if ($(this).children('a')
						.text()
						.toUpperCase()
						.includes(item)) {
						$(this).show();
					} else {
						$(this).hide();
					}
				}
			});
		});
		$('.api-list li:visible').parents("ul:not(.api-list)")
			.prev()
			.show();
		if ($('.api-list li:visible').text()
			.trim() === 'Quick Links') {
			$('.api-list li:visible').next()
				.click();
			$('.api-list li:visible').next()
				.children()
				.show();
		}
	}
};

// left-toggle button logic
function leftside_button(event) {
	// non-mobile devices
	if ($(window).width() < 991) {
		$('.middle-side').css({ 'margin-left': '15vw' });
		$('.knowledge-hub-article').css({ 'margin-left': '17vw' });
		if (!$('.left-side').is(':visible')) {
			$('.leftSidebar-wr').css('left', '90vw');
		} else {
			$('.leftSidebar-wr').css('left', '0vw');
		}
	} else {
		
		if (!$('.left-side').is(':visible')) {
			$('.middle-side').css({ 'margin-left': '24vw' });
			$('.knowledge-hub-article').css({ 'margin-left': '24vw' });
			$('.leftSidebar-wr').css('left', '20vw');
		} else {
			$('.middle-side').css({ 'margin-left': '5vw' });
			$('.knowledge-hub-article').css({ 'margin-left': '5vw' });
			$('.leftSidebar-wr').css('left', '0vw');
		}
	}

	$('.left-side').toggle(0, () => {
		if($('.left-side').is(':visible')) {
			$('.leftSidebar-wr i').removeClass();
			$('.leftSidebar-wr i').addClass('glyphicon glyphicon-remove');
			if ($(window).width() < 991) {
				$('.leftSidebar-wr').css({ left: '90vw' });
			} else {
				$('.leftSidebar-wr i').removeClass();
				$('.leftSidebar-wr i').addClass('glyphicon glyphicon-menu-left');
				$('.leftSidebar-wr').css({ left: '20vw' });
			}
		} else {
			$('.leftSidebar-wr').css({ left: '0vw' });
			if ($(window).width() < 991) {
				$('.leftSidebar-wr i').removeClass();
				$('.leftSidebar-wr i').addClass('glyphicon glyphicon-list');
			} else {
				$('.sidebar-button:eq(0) i').attr('class', 'glyphicon glyphicon-menu-right');
			}
		}
	});
}
$(document).ready(() => {
	$('.panel-heading').each(function () {
		$(this).click(() => {
			$(this)
			.find('.panel-title a')
			.toggleClass('active');
		$(this)
			.find('.panel-title i')
			.toggleClass('glyphicon-chevron-up glyphicon-chevron-down');
		});
	});
	let hide_shown = false;
	let lastScrollTop = 0;
	if($(window).width()<= 650) {
		$('.sections-wrapper').css({ 'margin-bottom': '20%' });
	} else {
		$('.sections-wrapper').css({ 'margin-bottom': '10%' });
	}
	
	$('.api-list-wr-content').css({ top: $('#header').height() });
	if ($('.middle-side').height() < 200) {
		$('.middle-side').css('margin-bottom', '50%');
	}
	$('.api-list').scrollTop($('.api-list .active').position().top);
	$(window).scroll(() => { // This function closes the sidebar upon scrolling far enough down the page
		// align sidebar by scroll event - keeping the sidebar aligned with the static header
		const scrollTop = $(window).scrollTop() + $(window).height();
		const doc_height = $(document).height();
		if (doc_height - scrollTop <= 100) {
			if ($('.left-side').is(':visible')) {
				$('.sidebar-button').click();
				hide_shown = true;
			}
		} else if (!$('.left-side').is(':visible')) {
			if (hide_shown) {
				$('.sidebar-button').click();
				hide_shown = false;
			}
		}
		if ($(window).width() < 992) {
			const st = window.pageYOffset || document.documentElement.scrollTop;
			if (st > lastScrollTop) {
				if ($(window).scrollTop() <= $('#header').height()) {
					$('.api-list-wr-content').css({ top: Math.abs($(window).scrollTop() - $('#header').height()) });
					$('.leftSidebar-wr').css('top', Math.abs($(window).scrollTop() - $('#header').height()));
				} else {
					$('.api-list-wr-content').css({ top: 0 });
					$('.leftSidebar-wr').css('top', 0);
				}
			} else if ($(window).scrollTop() <= $('#header').height()) {
				$('.api-list-wr-content').css({ top: $('#header').height() });
				$('.leftSidebar-wr').css('top', $('#header').height());
			} else {
				$('.api-list-wr-content').css({ top: 0 });
				$('.leftSidebar-wr').css('top', 0);
			}
			lastScrollTop = st <= 0 ? 0 : st;
		} else {
			$('.api-list-wr-content').css({ top: $('#header').height() });
		}
	});
	$('.left-side').css('height', '100%');
	$('.sidebar-button').eq(0)
		.click(leftside_button);// loading in the click button - different mechanism for larger devices

	if ($(window).width() >= 992) {
		$('.api-list-wr-content').css({ top: $('#header').height() });
		$('.leftSidebar-wr').css('left', '20vw');
		$('.rows').children()
			.each(function () {
				if ($(this).hasClass('left-side')) {
					$('.rows').css({ position: 'flex' });
				} else {
					$('.rows').css({ position: 'relative' });
				}
			});
	} else {
		$('.api-list-wr-content').css({ top: 0 });
		if ($(window).scrollTop() <= 81) { // exact top of the document
			$('.api-list-wr-content').css({ top: $('#header').height() });
		}

		if ($(window).width() < 991) {
			$('.middle-side').css('margin-left', '5em');
			$('.knowledge-hub-article').css('margin-left', '5em');
		} else {
			$('.middle-side').css('margin-left', '13em');
		}

		$('.leftSidebar-wr').css('left', '0%');
		$('.left-side').hide();
		if ($(window).width() <= 991) {
			$('.leftSidebar-wr i').removeClass();
			$('.leftSidebar-wr i').addClass('glyphicon glyphicon-list');
		} else {
			$('.leftSidebar-wr i').removeClass();
			$('.leftSidebar-wr i').addClass('glyphicon glyphicon-menu-right');
		}
	}

	// header resizing based on different pages
	if ($('body').attr('class')
		.includes('home-page') || $('body').attr('class')
		.includes('products-page') || $('body').attr('class')
		.includes('pricing-page')) {
		if ($(window).width() < 1076) {
			$('#header').children()
				.css({ width: '100%' });
			$('.sections-wrapper .container').css({ width: '100%' });
		} else {
			$('#header').children()
				.css({ width: '80%' });
			$('.sections-wrapper .container').css({ width: '80%' });
		}
	} else if ($('body').attr('class')
		.includes('api-product-page')) {
		$('.sections-wrapper .rows').css({ display: 'grid' });
	}

	$('.modal-footer').children('button')
	.each(function (li) {
		$(this).attr('id', li); 
		$(this).click(() => {
			$('.response-content').eq(li)
				.fadeOut();
		});
	});

	$('.modal-body').children()
		.each(function (li) {
			$(this).children()
				.attr('id', `apiResponse-${li}`); 
		});

		$('.myInput').focusout(() => {
		if ($('.myInput').val().length > 0) {
			$('.myInput').focus();
		}
	});
	// adding FHIR versioning to api-list
	$(".api-list li[data-target*='stu']").next()
		.each(function (li) {
			$(this).children()
				.children('a')
				.each(function (li) {
					if (typeof $(this).attr('href') !== 'undefined') {
						if ($(this).text()
							.toUpperCase()
							.match(/FHIR+\s[a-zA-Z]/)) {
							$(this).text(function () {
								return $(this).text()
									.replace(/FHIR/g, '');
							});
						}
					}
				});
		});
	// filtering api-list from user inputs
	$('.myInput').bind('change keyup input keypress', (e) => {
		const txt = $('.myInput').val()
			.toUpperCase()
			.trim();
		$('.api-list hr').each(function () {
			if ($(this).next()
				.is(':visible')) {
				$(this).show();
			} else {
				$(this).hide();
			}
		});
		if (e.which === 8) {
			if (txt.length > 0) {
				$('.api-list li[aria-expanded="false"]').click();
				$('.api-list li').show();
				$('.api-list ul[aria-expanded="true"]').show();
			}
		}
		$.fn.filterSidebar(txt);
	});
	/** **********************
	 ************************
      bread crumb logic starts
	 ************************
	 *********************** */
	let current_link = window.location.href;
	current_link = current_link.split(window.location.origin).join('');
	current_link = current_link.substring(0, current_link.lastIndexOf('/') + 1);
	let links = current_link.split('/');
	links = links.slice(1, length - 1);
	const display_links = [];
	links.forEach((item) => {
		if (item === links[links.length - 1]) {
			if (item.includes('FHIR')) {
				item = $('.middle-side .below-title:eq(1) div p').text()
					.substring(11);
				item = item.replace(/\//g, ' ');
				item = item.substring(1, 5).toUpperCase() + item.substring(5);
			} else if ($('.middle-side .api-title h3').length === 0) {
				item = $('.middle-side h1').text();
			} else {
				item = $('.middle-side .api-title div h3').text();
				item = item.charAt(0).toUpperCase() + item.substring(1);
			}
		} else {
			item = item.replace(/\//g, ' ');
			item = item.replace(/-/g, ' ');
			if (item.toLowerCase().includes('fhir')){
				item = item
				.split(' ')
				.map(word => word.toUpperCase())
				.join(' ');
			}
			else{
				item = item.charAt(0).toUpperCase() + item.substring(1);
			}
		}
		display_links.push(item);
	});
	while (current_link.length > 1) {
		if (display_links.length === 1) {
			$('.breadcrumb').prepend(`<li class='breadcrumb-item'><a href='${current_link}'  aria-current='page'>${display_links.pop()}</a></li>`);
			$('.breadcrumb').prepend(`<li class='breadcrumb-item'><a href='${window.location.origin}'  aria-current='page'>Home</a></li>`);
		} else {
			$('.breadcrumb').prepend(`<li class='breadcrumb-item'><a href='${current_link}'  aria-current='page'>${display_links.pop()}</a></li>`);
		}
		current_link = current_link.substring(0, current_link.length - 1);
		current_link = current_link.substring(0, current_link.lastIndexOf('/') + 1);
	}
	$('.breadcrumb .breadcrumb-item').last()
		.addClass('active');

	/** **********************
	 ************************
      bread crumb logic ends
	 ************************
	 *********************** */
	 	if ($('.method').length > 0) {
		// adding IDs for hyperlinks
		$('.method-title').each(function (li) {
			$(this).attr('id', li);
		});
		// adding methods to the sidebar
		$('.api-list li').each(function () {
			if ($(this).attr('class') === 'active') {
				if ($(this).attr('data-target') === '#selectedAPI') {
					$(window).scroll(() => {
						const heights = [];
						$('.method-name').each(function (li) {
							heights.push($(this).offset().top - 200);
						});
						const height = $(window).scrollTop();
						heights.forEach((li) => {
							if (height >= li) {
								const index = heights.indexOf(li);
								$('#selectedAPI li').removeClass('active');
								$('#selectedAPI li').eq(index)
									.attr('class', 'active');
							}
						});
					});
					$('.api-list #selectedAPI').children()
						.on('click', function (event) {
							$('#selectedAPI li').removeClass('active');
							$(this).addClass('active');
							if ($(window).width() < 992) {
								if ($('.left-side').is(':visible')) {
									$('.left-side').hide();
									$('.sidebar-button:eq(0) i').attr('class', 'glyphicon glyphicon-list');
									$('.leftSidebar-wr').animate({ left: '0%' });
								}
							}
							const index = $(this).index();
							$('html, body').animate({
								scrollTop: $('.method-name').eq(index)
									.offset().top - 80
							}, 'slow');
						});
				}
			}
		});
	}
});

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = '/assets/js/mobile.js';
	$('head').append(script);
} else {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = '/assets/js/modifier.js';
	$('head').append(script);
}
